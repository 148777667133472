import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app.js";
import reportWebVitals from "./reportWebVitals";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
// 	apiKey: "AIzaSyDOV337yPD8qf534qCyiOYfe4C0sP2yXRY",
// 	authDomain: "stayupolknights-website.firebaseapp.com",
// 	databaseURL: "https://stayupolknights-website.firebaseio.com",
// 	projectId: "stayupolknights-website",
// 	storageBucket: "stayupolknights-website.appspot.com",
// 	messagingSenderId: "332032529721",
// 	appId: "1:332032529721:web:8a22e88a5b0d640d9a84e8",
// 	measurementId: "G-WS6HWFJJ43",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
