import React, { Component } from "react";
import TeamMember from "./teamMember";

export default class OurTeam extends Component {
	render() {
		const team = [
			{
				name: "Caleb Isaacson",
				title: "Co-Founder & CEO",
				photoUrl: "Caleb.jpg",
				// email: "caleb@stayupolknights.com",
				bio: "",
			},
			{
				name: "Nate Taylor",
				title: "Co-Founder & COO",
				photoUrl: "Nate.jpg",
				// email: "nate@stayupolknights.com",
				bio: "",
			},
		];
		return (
			<div className="container container_team">
				<h1>Our Team</h1>
				<div className="container_profiles">
					{team.map((memberInfo, i) => (
						<TeamMember key={i} info={memberInfo} />
					))}
				</div>
				<a href="mailto:stayupolknights@gmail.com" target="_blank" rel="noreferrer">
					<h3>team@stayupolknights.com</h3>
				</a>
			</div>
		);
	}
}
