import React, { Component } from "react";

export default class HomePage extends Component {
	render() {
		return (
			<div className="container_homepage">
				<div className="content-container banner">
					<img src="/assets/logo.png" className="banner_img" alt="Stayupol Knights Logo" />
					<div className="banner_title">
						<h1 className="banner_title_name">
							stayupol<span className="space">&nbsp;</span>knIghts
						</h1>
						<h1 className="banner_title_tagline">Get the most out of your gameplay</h1>
					</div>
				</div>
				<div className="content-container welcome-message">
					<span className="side-border mid"></span>
					<span className="side-border forward"></span>
					<div>
						<h1>Who we are</h1>
						<p>
							<text>We're here to help improve your gaming experiences</text>
							<text>
								Follow us on social media for tips on how to make each of your gaming sessions more
								<span className="highlight"> uplifting</span> and <span className="highlight">satisfying</span>. We're so excited
								you're here and we can't wait to making a difference in the world of gaming.
							</text>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
