import React, { Component } from "react";
import { PulseLoader } from "react-spinners";

export default class LoadingIcon extends Component {
	render() {
		return (
			<div>
				<PulseLoader className="loading-icon" />
			</div>
		);
	}
}
