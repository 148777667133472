import React, { Component } from "react";

export default class TeamMember extends Component {
	render() {
		let info = this.props.info;
		return (
			<div className="container_member">
				<h3 className="member-name">
					{`${info.name},`}
					<br />
					{info.title}
				</h3>
				<img src={`/assets/team/${info.photoUrl}`} alt={`${info.name}, ${info.title}`} />
				<a href={`mailto:${info.email}`} className="member-email" target="_blank" rel="noreferrer">
					{info.email}
				</a>
				<div className="member-bio">{info.bio}</div>
			</div>
		);
	}
}
