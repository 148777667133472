import React, { Component } from "react";

import { NavLink } from "react-router-dom";

export default class Header extends Component {
	toggleNavbar = () => {
		let navbar = document.querySelector(".navbar");
		navbar.classList.toggle("collapsed");
	};
	render() {
		return (
			<div className="navbar collapsed">
				<div className="mobile-nav">
					<button onClick={this.toggleNavbar} className="menu-bars">
						<i className="fa fa-bars"></i>
					</button>
					{/* <NavLink to="/" className="logo" onClick={this.toggleNavbar}>
						<img src="assets/logo.png" alt="Stayupol Knights Logo" />
						<span className="title">Stayupol knIghts</span>
					</NavLink> */}
				</div>
				<div className="navbar_items">
					<NavLink to="/" className="logo" onClick={this.toggleNavbar}>
						<img src="/assets/logo.png" alt="Stayupol Knights Logo" />
						{/* <span className="title">Stayupol knIghts</span> */}
					</NavLink>
					<NavLink to="/" onClick={this.toggleNavbar}>
						Home
					</NavLink>
					<NavLink to="/games" onClick={this.toggleNavbar}>
						Errand Task Force
					</NavLink>
					<NavLink to="/our-team" onClick={this.toggleNavbar}>
						Our Team
					</NavLink>
					<NavLink to="/email-signup" onClick={this.toggleNavbar}>
						Email Signup
					</NavLink>
					{/* <NavLink to="/join-the-order" onClick={this.toggleNavbar}>
						Jobs
					</NavLink> */}
					{/* <NavLink to="/blog" onClick={this.blog}>Blog</NavLink> */}
				</div>
			</div>
		);
	}
}
