import React from "react";

export const ETFSignup = () => {
	return (
		<div className="signup">
			<h2>Wishlist Errand Task Force on Steam!</h2>
			<iframe
				className="steam-embed"
				title="Wishlist Errand Task Force on Steam!"
				src="https://store.steampowered.com/widget/3053830/"
			></iframe>
			<a className="mobile-cta button" href="https://store.steampowered.com/app/3053830/Errand_Task_Force/" target="_blank" rel="noreferrer">
				Wishlist on Steam <i className="fas fa-external-link-alt" />
			</a>
		</div>
	);
};
