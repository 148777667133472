import React, { Component } from "react";

export default class Article extends Component {
	toggleDescription() {
		//this.info.description.classList.add("active");
	}
	render() {
		let info = this.props.info;
		return (
			<div className="container_article">
				<button className="article-button" onClick={this.toggleDescription}>
					<h3 className="article-title">{info.title}</h3>
				</button>
				<div className="article-content hidden">
					{/* <img src={`/assets/team/${info.photoUrl}`} alt={`${info.name}, ${info.title}`} /> */}
					{info.description}
				</div>
			</div>
		);
	}
}
