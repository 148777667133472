import React, { Component } from "react";
import Article from "./article";

export default class Blog extends Component {
	constructor() {
		super();
		this.state = {
			articles: [
				{
					title: "Set a Timer",
					description: "texty text",
				},
				{
					title: "Drink Water",
					description: "le agua es muy delicioso",
				},
				{
					title: "Set a Timer",
					description: "texty text",
				},
				{
					title: "Drink Water",
					description: "le agua es muy delicioso",
				},
			],
		};
	}
	componentDidMount() {
		document
			.querySelector(".container_articles")
			.querySelectorAll(".container_article")
			.forEach((article) => {
				let content = article.querySelector(".article-content");
				article.addEventListener("mouseenter", () => {
					article.querySelector(".article-button").classList.toggle("expanded");
					content.classList.toggle("hidden");
					if (content.style.maxHeight) {
						content.style.maxHeight = null;
					} else {
						content.style.maxHeight = content.scrollHeight + "20px";
					}
				});
				article.addEventListener("mouseleave", () => {
					article.querySelector(".article-button").classList.toggle("expanded");
					content.classList.toggle("hidden");
					if (content.style.maxHeight) {
						content.style.maxHeight = null;
					} else {
						content.style.maxHeight = content.scrollHeight + "20px";
					}
				});
			});
	}
	render() {
		return (
			<div className="container_blog">
				<h1>Intentional Gameplay Tips</h1>
				<div className="container_articles">
					{this.state.articles.map((articleInfo) => (
						<Article info={articleInfo} />
					))}
				</div>
			</div>
		);
	}
}
