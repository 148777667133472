import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// import Analytics from "react-router-ga";

import "./styles/app.scss";
import HomePage from "./components/homepage";
import Header from "./components/header";
import Footer from "./components/footer";
import EmailSignup from "./components/emailSignup";
import CareerSignup from "./components/careerSignup";
import ETF, { ETFPitchDeck } from "./components/errand-task-force";
import OurTeam from "./components/ourTeam";
import Blog from "./components/blog";
import PrivacyPolicy from "./components/privacy-policy";

export const App = () => {
	return (
		<Router className="app">
			<Header />
			{/* <Analytics id="UA-141199245-2" debug> */}
			<Routes>
				<Route index element={<HomePage />} />
				<Route exact path="/" element={<HomePage />} />
				{/* <Route path="/games" element={<games/>} /> */}
				<Route exact path="/errand-task-force" element={<ETF />}>
					{/* <Route path="pitch" element={<ETFPitchDeck />} />
					<Route path="pitch-deck" element={<ETFPitchDeck />} />
					<Route path="ad:adlink" element={<Navigate to="/errand-task-force" />} /> */}
				</Route>
				<Route path="/errand-task-force/pitch" element={<ETFPitchDeck />} />
				<Route path="/errand-task-force/pitch-deck" element={<ETFPitchDeck />} />
				<Route exact path="/etf" element={<Navigate to="/errand-task-force" />} />
				<Route exact path="/errandtaskforce" element={<Navigate to="/errand-task-force" />} />
				<Route path="/games" element={<Navigate to="/errand-task-force" />} />

				<Route path="/etf-pitch-deck" element={<ETFPitchDeck />} />
				<Route path="/etf/pitch-deck" element={<Navigate to="/etf-pitch-deck" />} />
				<Route path="/etf/pitch" element={<Navigate to="/etf-pitch-deck" />} />
				<Route path="/etf-pitch" element={<Navigate to="/etf-pitch-deck" />} />
				<Route path="/errandtaskforce/pitch" element={<Navigate to="/etf-pitch-deck" />} />

				<Route path="/our-team" element={<OurTeam />} />
				<Route path="/jobs" element={<CareerSignup />} />
				<Route path="/join-the-order" element={<CareerSignup />} />
				<Route path="/email-signup" element={<EmailSignup />} />
				<Route path="/blog" element={<Blog />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			</Routes>
			{/* </Analytics> */}
			<Footer />
		</Router>
	);
};
