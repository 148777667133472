import React, { Component } from "react";
import LoadingIcon from "./loadingBar";

export default class CareerSignup extends Component {
	constructor() {
		super();
		this.state = {
			loaded: false,
		};
	}

	render() {
		return (
			<div className="container clickup-form">
				{!this.state.loaded && <LoadingIcon />}
				<iframe
					title="Email Signup Form"
					className="clickup-embed"
					src="https://forms.clickup.com/f/15mxr-1904/EOCCVRZOWPK9C8I26D"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="100%"
					scrolling="yes"
					style={{
						background: "transparent",
						opacity: this.state.loaded ? 1 : 0,
						height: this.state.loaded ? "100%" : 0,
					}}
					onLoad={() => this.setState({ loaded: true })}
				></iframe>
				<script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
			</div>
		);
	}
}
