import React, { Component } from "react";

import { NavLink } from "react-router-dom";

export default class Footer extends Component {
	render() {
		return (
			<div className="container_footer">
				<div className="social-list full-width">
					<a href="https://www.facebook.com/Stayupol-Knights-106052747641938" className="social-item facebook-icon">
						<img src="/assets/icons/facebook.png" alt="facebook Logo" />
					</a>
					{/* <a href="https://twitter.com/stayupolknights" className="social-item twitter-icon">
						<img src="/assets/icons/twitter.png" alt="twitter Logo" />
					</a> */}
					<a href="https://www.instagram.com/stayupolknights" className="social-item instagram-icon">
						<img src="/assets/icons/Instagram_Glyph_White.svg" alt="instagram Logo" />
					</a>
					<a href="https://www.tiktok.com/@stayupol.knights" className="social-item tiktok-icon">
						<img src="/assets/icons/TikTok-Social-Icon-Circle-White.svg" alt="tiktok Logo" />
					</a>
					<a href="https://discord.gg/evCXwSt" className="social-item discord-icon">
						<img src="/assets/icons/discord-mark-white.svg" alt="discord Logo" />
					</a>
					<a href="mailto:team@stayupolknights.com" className="social-item email-icon" target="_blank" rel="noreferrer">
						<i className="fa fa-envelope" />
					</a>
				</div>

				<div className="legal">
					<div className="footer-links">
						<NavLink to="/" onClick={this.toggleNavbar}>
							Home
						</NavLink>
						&nbsp;|&nbsp;
						<NavLink to="/errand-task-force" onClick={this.toggleNavbar}>
							Errand Task Force
						</NavLink>
						&nbsp;|&nbsp;
						<NavLink to="/our-team" onClick={this.toggleNavbar}>
							Our Team
						</NavLink>
						&nbsp;|&nbsp;
						<NavLink to="/email-signup" onClick={this.toggleNavbar}>
							Email Signup
						</NavLink>
						{/* &nbsp;|&nbsp;
						<NavLink to="/join-the-order" onClick={this.toggleNavbar}>
							Jobs
						</NavLink> */}
						&nbsp;|&nbsp;
						<NavLink to="/privacy-policy" onClick={this.toggleNavbar}>
							Privacy Policy
						</NavLink>
					</div>
					<a href="mailto:team@stayupolknights.com" target="_blank" rel="noreferrer">
						team@stayupolknights.com
					</a>
					<div>&copy; {new Date().getFullYear()} - Stayupol Knights, LLC</div>
				</div>
			</div>
		);
	}
}
