import React, { Component } from "react";
import { ETFSignup } from "./etf-signup";
import { Outlet } from "react-router";

export default class ETF extends Component {
	render() {
		return (
			<div className="container container_games_etf">
				<Outlet />
				<div className="game-banner">
					<a
						className="game-title"
						href={this.props.showPitchDeck ? this.props.pitchDeckLink : "https://store.steampowered.com/app/3053830/Errand_Task_Force/"}
						target="_blank"
						rel="noreferrer"
					>
						{/* <h1>
							<div className="title-text">
								<span>
									<i className="far fa-check-square" />
									&nbsp;Errand
								</span>
								<span>
									<i className="far fa-check-square" />
									&nbsp;Task
								</span>
								<span>
									<i className="far fa-square" />
									&nbsp;Force
								</span>
							</div>
						</h1> */}
						<img src="/assets/ETF/ETF Logo.png" alt="Errand Task Force Logo" className="etf-logo" />
					</a>
					<br />
					{this.props.showPitchDeck ? (
						<a className="btn button" href={this.props.pitchDeckLink} target="_blank" rel="noreferrer" role="button">
							Pitch Deck & Latest Build
							<i className="fas fa-external-link-alt" />
						</a>
					) : (
						<span className="description">
							<h2 className="title-tagline">Does your squad have what it takes?</h2>
							<h2 className="coming-soon">
								An extreme co-op party game
								<br />
								coming soon to
								<a href="https://store.steampowered.com/app/3053830/Errand_Task_Force/" target="_blank" rel="noreferrer">
									<img className="steam-logo" alt="Steam logo" src="/assets/icons/Steam_Logo.png" />
								</a>
							</h2>
						</span>
					)}
				</div>
				<ETFSignup />
				<div className="content-section black-grey">
					<iframe
						className="video"
						src="https://www.youtube.com/embed/wpjnPwWZYjw?si=bbxa4nESdcbIEBKi"
						title="Errand Task Force Gameplay Video"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerPolicy="strict-origin-when-cross-origin"
						allowFullScreen
					></iframe>
					<div className="content-section_text">
						<h2>
							A game where running errands&nbsp;
							<br />
							is an extreme sport.
						</h2>
						<ul>
							<li>
								<i className="far fa-check-square" />
								<div className="checklist-item">Race around the city with your friends</div>
							</li>
							<li>
								<i className="far fa-check-square" />
								<div className="checklist-item">Divide and conquer in the grocery store</div>
							</li>
							<li>
								<i className="far fa-check-square" />
								<div className="checklist-item">Drop by the bank on your way to class</div>
							</li>
							<li>
								<i className="far fa-check-square" />
								<div className="checklist-item">Leave the barber shop mid-haircut to drive your friend to work</div>
							</li>
							<li>
								<i className="far fa-square" />
								<div className="checklist-item">Check off your to-do list before closing time</div>
							</li>
						</ul>
					</div>
				</div>
				<ETFSignup />
				<div className="content-section cta blue">
					<h2>
						There's so much to do and so little time. <br />
						Does your squad have what it takes?
					</h2>
				</div>
			</div>
		);
	}
}

export const ETFPitchDeck = (props = { fullPage: false }) => {
	const pitchDeckLink = "https://drive.google.com/drive/folders/1UFSohThHrJb-VRHuh53rH0VtvK_6OPGT?usp=sharing";

	// const redirectBanner = () => (
	// 	<div className="etf-pitch container">
	// 		<h2>Looking for our Pitch Deck?</h2>
	// 		<a href={pitchDeckLink} /* onClick={window.open(pitchDeckLink, "_blank")} */ target="_blank" rel="noreferrer">
	// 			<u>View Our Pitch Deck</u>
	// 		</a>
	// 	</div>
	// );

	return props.fullPage ? (
		<div className="container">
			{/* {redirectBanner()} */}
			<ETF showPitchDeck={true} pitchDeckLink={pitchDeckLink} />
		</div>
	) : (
		<ETF showPitchDeck={true} pitchDeckLink={pitchDeckLink} />
		// redirectBanner()
	);
};
