import React, { Component } from "react";
import LoadingIcon from "./loadingBar";

export default class EmailSignup extends Component {
	constructor() {
		super();
		this.state = {
			loaded: false,
		};
	}

	render() {
		return (
			<div className="container">
				{!this.state.loaded && <LoadingIcon />}
				<div className="embed-form">
					<iframe
						title="Join the Order Signup Form"
						className="mailchimp-embed"
						src="https://eepurl.com/gYKGGj"
						width="100%"
						height="100%"
						style={{
							background: "transparent",
							opacity: this.state.loaded ? 1 : 0,
							height: this.state.loaded ? "100%" : 0,
						}}
						onLoad={() => this.setState({ loaded: true })}
					></iframe>
					{/* <iframe
					title="Join the Order Signup Form"
					className="clickup-embed"
					src="https://forms.clickup.com/f/15mxr-1890/Y5CIKL2K0REDV27RBQ"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="100%"
					scrolling="yes"
					style={{
						background: "transparent",
					}}
				></iframe>
				<script
					async
					src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
				></script> */}
				</div>
			</div>
		);
	}
}
